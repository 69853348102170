import React, { Dispatch, useCallback, useEffect, useState } from 'react'
import { Checkbox, Input } from '@ftdr/blueprint-components-react'
import { TextComponent as Text } from 'src/components/custom-fdr-components'
import { useDispatch, useSelector } from 'react-redux'
import { AppState, IAnyObject, ParsedFilter } from 'src/utils/shared-types'
import {
  toggleFilter,
  clearFilter,
  selectFilters,
  SelectedFilers,
} from '../../store/filters-store'
import { isDigit, isPartialPrice, isRange } from 'src/utils/validation-utils'
import _debounce from 'lodash.debounce'
import { useHistory, useLocation } from 'react-router'
import qs from 'query-string'
import {
  objectKeysToParams,
  snakeCaseToString,
  paramsToObjectKeys,
  qsConfig,
} from './helpers'
import { isHSA } from 'src/utils/tenant-helper'

type ChangeEvent = React.ChangeEvent<HTMLInputElement>
interface FilterOptionProps {
  hideClear?: boolean
  filter: ParsedFilter
  setActiveIndexes?: Dispatch<number[]>
}

export const FilterOption = ({
  filter,
  hideClear = false,
}: FilterOptionProps) => {
  const color = isHSA() ? 'primary' : 'interactive'
  const dispatch = useDispatch()
  const { selected: selectedFilters } = useSelector(
    (state: AppState) => state.filters
  )
  const location = useLocation()
  const history = useHistory()

  const [min, setMin] = useState<string>(
    ((selectedFilters[filter.name]?.[0] as string) || '')?.split('-')[0]
  )
  const [max, setMax] = useState<string>(
    ((selectedFilters[filter.name]?.[0] as string) || '')?.split('-')[1] || ''
  )
  const [isValid, setIsValid] = useState<boolean>(false)

  const [isMinDirty, setIsMinDirty] = useState<boolean>(false)
  const [isMaxDirty, setIsMaxDirty] = useState<boolean>(false)

  const debounce = useCallback(
    (range: string) => {
      _debounce(() => {
        const { pathname, search } = location
        const formattedSearch = snakeCaseToString(search)
        const paramsObject = qs.parse(formattedSearch)

        const filterObject = {}

        if (range === '0-0' || !isRange(range)) {
          delete paramsObject[filter.name]
        } else {
          filterObject[filter.name] = range
        }

        const paramsString = qs.stringify(
          objectKeysToParams({
            ...paramsObject,
            ...filterObject,
          })
        )
        history.push(`${pathname}?${paramsString}`)
      }, 500)()
    },

    [location, history, filter.name]
  )

  const updateSelectedFilters = (filters: SelectedFilers) => {
    dispatch(selectFilters(filters))
  }
  const historyPush = (param: IAnyObject) => {
    const { pathname, search } = location
    const paramsObject = paramsToObjectKeys(qs.parse(search, qsConfig))

    if (paramsObject?.page) {
      delete paramsObject?.page
    }
    const paramsString = qs.stringify(
      objectKeysToParams({ ...paramsObject, ...param } as {
        [key: string]: string[]
      }),
      qsConfig
    )
    updateSelectedFilters(paramsObject)
    history.push(`${pathname}?${paramsString}`)
  }

  const historyClear = (key: string, value?: string) => {
    const { pathname, search } = location
    const paramsObject = paramsToObjectKeys(qs.parse(search, qsConfig))
    delete paramsObject.page
    if (paramsObject[key]) {
      if (value && typeof paramsObject[key] !== 'string') {
        paramsObject[key] =
          paramsObject[key]?.filter((v: string) => v !== value) || []
      } else {
        delete paramsObject[key]
      }
    }

    const paramsString = qs.stringify(
      objectKeysToParams({ ...paramsObject }),
      qsConfig
    )
    history.push(`${pathname}?${paramsString}`)
  }

  const updateRange = (min: string | null, max: string | null) => {
    let range = ''
    if (min && max) {
      range = `${min}-${max}`
    } else if (min) {
      range = `${min}-0`
    } else if (max) {
      range = `0-${max}`
    }
    debounce(range)
  }

  const onMinChange = (e: ChangeEvent, fieldName: string) => {
    const nextMin = e.target.value
    if (fieldName === 'price') {
      if (!isPartialPrice(nextMin)) return
    } else {
      if (!isDigit(nextMin)) return
    }
    setIsMinDirty(true)
    setMin(nextMin)
  }

  const onMaxChange = (e: ChangeEvent, fieldName: string) => {
    const nextMax = e.target.value
    if (fieldName === 'price') {
      if (!isPartialPrice(nextMax)) return
    } else {
      if (!isDigit(nextMax)) return
    }
    setIsMaxDirty(true)
    setMax(nextMax)
  }
  useEffect(() => {
    if (/\.$/.test(min) || /\.$/.test(max)) return
    const a: number = parseFloat(min) || 0
    const b: number = parseFloat(max) || 0

    if (a === 0 && b === 0) {
      setIsValid(true)
      if (isMinDirty || isMaxDirty) updateRange(min, max)
      return
    }
    if (b >= a || b === 0) {
      setIsValid(true)
      updateRange(min, max)
    } else {
      setIsValid(false)
    }
    //eslint-disable-next-line
  }, [min, max])

  return (
    <div>
      {!hideClear && (
        <div
          id={`${filter.name}-filter-options-clear`}
          className="ml-1 my-2 flex items-center cursor-pointer"
          onClick={() => {
            if (filter.type === 'range') {
              setMin('')
              setMax('')
            }
            historyClear(filter.name)
            dispatch(clearFilter(filter.name))
          }}
        >
          <Text
            variant="label"
            className="uppercase underline"
            color="primary"
            textTemplateKey="BROWSE_CLEAR_FILTER"
          />
        </div>
      )}
      <div className="flex flex-col">
        {filter.type === 'enum' ? (
          filter.values?.map((value, i) => (
            <Checkbox
              id={`${filter.name.replace(
                /\s/g,
                '_'
              )}-filter-options-radio-${i}`}
              color={color}
              key={value}
              label={value}
              checked={selectedFilters[filter.name]?.includes(value)}
              onChange={() => {
                const { name } = filter
                const currentValues = selectedFilters?.[filter.name] || []

                dispatch(toggleFilter(name, value))
                !selectedFilters[filter.name]?.includes(value)
                  ? historyPush({
                      [name]: [...currentValues, value],
                    })
                  : historyClear(name, value)
              }}
              className="my-2"
            />
          ))
        ) : (
          <>
            <div className="flex items-center">
              <Input
                id={`${filter.name.replace(/\s/g, '_')}-filter-options-min`}
                formField
                label=""
                placeholder="Min"
                value={min}
                onChange={(e) => onMinChange(e, filter.name)}
              />
              <div className="mx-2 font-black">–</div>
              <Input
                id={`${filter.name.replace(/\s/g, '_')}-filter-options-max`}
                formField
                label=""
                inputBorderClassName={
                  !isValid ? 'focus:border-error border-error' : ''
                }
                placeholder="Max"
                value={max}
                onChange={(e) => onMaxChange(e, filter.name)}
              />
            </div>
            {!isValid && (
              <Text
                id={`${filter.name.replace(/\s/g, '_')}-filter-options-error`}
                variant="caption"
                className="py-2 text-right"
                color="error"
                textTemplateKey="FILTER_ERROR_MESSAGE"
              />
            )}
          </>
        )}
      </div>
    </div>
  )
}
