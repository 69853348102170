import React from 'react'
import { useAppContext, Notification } from '@ftdr/blueprint-components-react'
import {
  ButtonComponent as Button,
  TextComponent as Text,
} from '../custom-fdr-components'
import { IconAddCircledOutline } from '@ftdr/blueprint-components-react'
import { useAdminContext } from '../../hooks/use-admin-context'
import { IconCheck } from '@ftdr/blueprint-components-react'
import { api } from 'src/utils/api'
import Vendor from './vendor'
import Message from './message'
import { emptyCacheObject } from './initials'
import { createdEndpoint } from './helpers'
import { saveAs } from 'file-saver'

interface IProps {
  showRefreshMessage?: boolean
}
const Replace: React.FC<IProps> = ({ showRefreshMessage = false }) => {
  const {
    appSettings: { localizedText },
  } = useAppContext()
  const {
    setError,
    isfileDownloaded,
    setIsFileDownloaded,
    isLoading,
    file,
    selectedVendor: { id: vendor },
    setFile,
    adminFunction: { value: action },
    addFileMessage: { error, message },
    setAddFileMessage,
    setIsLoading,
  } = useAdminContext()
  const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event?.target?.files
    files?.length && setFile(files[0])
    setAddFileMessage(emptyCacheObject)
  }
  const onClickHandler = async () => {
    setIsLoading(true)
    const path = createdEndpoint(
      action === 'no_delivery' ? action.replace('_', '-') : action,
      vendor
    )
    const res = await api.adminReplaceFile(path, file)
    setAddFileMessage(res)
    setFile(null)
    setIsLoading(false)
  }
  const onClickDownloadHandler = async (
    e: React.MouseEvent<HTMLSpanElement, MouseEvent>
  ) => {
    e.preventDefault()
    const path = createdEndpoint(
      action === 'no_delivery' ? action.replace('_', '-') : action,
      vendor
    )
    if (vendor || action === 'coupa') {
      try {
        const { data: downloadCSV, fileName } = await api.getAdminData(path)
        const file = new Blob([downloadCSV], {
          type: 'text/csv;charset=utf-8',
        })

        setError(null)

        saveAs(file, fileName)
        setIsFileDownloaded(true)
      } catch (error: any) {
        setError(error.message)
      }
    } else {
      return
    }
  }
  return (
    <div>
      <Vendor showButton={false} />
      {showRefreshMessage === true ? (
        <Notification
          status="warning"
          size="small"
          showStatusLabel={false}
          className="mb-6"
        >
          {localizedText('ADMIN_REFRESH_MESSAGE')}
        </Notification>
      ) : null}
      <div className="relative inline-block mb-2">
        <Button
          id="replace-button"
          className="relative index-0"
          variant="filled"
          size="small"
          label={localizedText('ADMIN_BUTTON_BROWSE')}
          startIcon={<IconAddCircledOutline />}
        />
        <input
          id="replace-file-input"
          type="file"
          accept="text/csv"
          className="block absolute left-0 top-0 block w-full h-full"
          style={{
            opacity: 0,
            zIndex: 2,
            cursor: 'pointer',
          }}
          onChange={onChangeHandler}
        />
      </div>
      {!!file && (
        <div id="replace-file-success" className="flex items-center">
          <IconCheck color="success" size={16} className="mr-2" />
          <Text>File {file.name} is added</Text>
        </div>
      )}
      <div className="mt-4" id="replace-is-file-downloaded">
        {!isfileDownloaded && (
          <Text className="text-primary my-2">
            <span
              className="text-warning-600 cursor-pointer"
              onClick={(e) => onClickDownloadHandler(e)}
            >
              Download
            </span>{' '}
            original file before replacing.
          </Text>
        )}
        {isfileDownloaded && (
          <Button
            id="replace-button-replace"
            className="relative  ml-0"
            variant="filled"
            size="medium"
            label={localizedText('ADMIN_BUTTON_REPLACE')}
            loading={isLoading}
            disabled={action === 'coupa' ? !file : !file || !vendor}
            onClick={onClickHandler}
          />
        )}
      </div>
      <Message id="replace" error={error} message={message} />
    </div>
  )
}

export default Replace
