import yup, { valueSelected, customUuid } from '../../../../utils/yup'

export const paymentSchema = yup
  .object()
  .shape({
    email: yup.string().when('isPartial', {
      is: (isPartial: boolean) => isPartial,
      then: yup.string().partialEmail(false),
      otherwise: yup.string().email(false),
    }),
    dispatchID: yup.string().dispatchID(false),
    id: customUuid(yup, false),
    contractID: yup.string().contractID(false),
    paymentID: yup.string().paymentID(false),
    companyCode: yup.string(),
    vendor: valueSelected(yup, false),
    modelNumber: yup.string().modelNumber(false),
    priceMin: yup.string().decimalAmount(false),
    priceMax: yup.string().decimalAmount(false),
    orderID: yup.string().orderID(false),
  })
  .when((values, schema) => {
    if (values.priceMin) {
      return schema.shape({
        priceMax: yup.string().decimalAmountMaxRange(values),
      })
    }
  })
