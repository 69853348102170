import React, { useEffect, useState, useMemo } from 'react'
import { useParams, Link, useHistory, useLocation } from 'react-router-dom'
import { api } from '../../utils/api'
import {
  IconNavArrowLeft,
  useAppContext,
} from '@ftdr/blueprint-components-react'
import {
  TextComponent as Text,
  ButtonComponent as Button,
  ProgressIndicatorComponent as ProgressIndicator,
} from 'src/components/custom-fdr-components'
import { appliancepb } from '../../services/protobuf-models/appliance-ms-protobuf-models'
import Carousel from 'src/components/carousel/carousel'
import { ProductAttributes } from './product-attributes'
import { ProductFeatures } from './product-features'
import { formatCentsCurrency } from 'src/utils/internationalization-helper'
import { DispatchIdCard } from 'src/components/dispatch-id-card'
import { useSelector, useDispatch } from 'react-redux'
import { addReplacement } from 'src/store/compare-store'
import { AppState, RequestStatus } from 'src/utils/shared-types'
import { useMedia } from '../../hooks/use-media'
import DeliveryDate from '../../components/delivery-date/delivery-date'
import { SelectedSlideProvider } from 'src/hooks/use-slide-context'
import { trimKitList } from 'src/utils/trim-kit-list'
import { refrigeratorIcemakerList } from 'src/utils/refrigerator-icemaker-list'

interface ProductDetailParams {
  productId: string
  replacementId: string
}

type NullableProduct = appliancepb.Product | null
export const ProductDetailPage = () => {
  const {
    appSettings: { localizedText },
  } = useAppContext()
  const trimKitSize = useSelector((state: AppState) => state.survey.trimKitSize)
  const icemakerAddon = useSelector(
    (state: AppState) => state.survey.icemakerAddon
  )
  const acceptedCategories = useSelector(
    (state: AppState) => state.survey.acceptedCategories
  )

  const history = useHistory()
  const location = useLocation()
  const dispatch = useDispatch()
  const { productId, replacementId } = useParams<ProductDetailParams>()
  const [product, setProduct] = useState<NullableProduct>(null)
  const [productAvailability, setProductAvailability] = useState<
    NullableProduct
  >(null)
  const [trimKit, setTrimKit] = useState('')
  const [icemaker, setIcemaker] = useState('')
  const [accessory, setAccessory] = useState<NullableProduct>(null)
  const [loadingAccessory, setLoadingAccessory] = useState<boolean>(false)
  const [isGiftCard, setIsGiftCard] = useState<boolean>(false)
  const [error, setError] = useState<any>()
  const [confirmStatus, setConfirmStatus] = useState<RequestStatus>('pending')
  const [isConflictAlert, setIsConflictAlert] = useState<boolean>(false)

  const searchCategories = useSelector((state: AppState) =>
    state.survey.acceptedCategories.join(' or ').toLowerCase()
  )
  const itemId = useSelector(
    (state: AppState) => state.survey.agentSurvey.item?.id
  )
  const dcov = useSelector((state: AppState) => state.survey.agentSurvey.dcov)

  const partRequestId = useSelector(
    (state: AppState) =>
      state.survey.agentSurvey.partRequestId ||
      state.replacement.data?.replacementDetails?.partRequestID
  )
  const addressId = useSelector(
    (state: AppState) =>
      state.survey.agentSurvey.dispatch?.addressID ||
      state.replacement.data?.customer?.address?.ID
  )
  const cashInLieu = useSelector(
    (state: AppState) => state.replacement.data?.replacementDetails?.cashInLieu
  )
  const id = replacementId || itemId || ''
  const { unit, height, width, depth } = product?.dimensions || {}
  const parsedUnit = unit === 'in' ? 'inches' : unit || ''
  const isCustomer = location.pathname.includes('/shop')
  const isDesktop = useMedia('(min-width:1024px)')
  const memoGiftCard = useMemo(() => isGiftCard, [isGiftCard])
  useEffect(() => {
    dispatch(addReplacement(replacementId))
    //eslint-disable-next-line
  }, [])
  useEffect(() => {
    // First api call to get general information
    const getProduct = async () => {
      // TODO: rename this method in api
      try {
        const product = await api.getReplacementProduct(
          id,
          productId,
          addressId || '',
          partRequestId || '',
          dcov,
          trimKitSize,
          icemakerAddon
        )

        setIsGiftCard(/^BH-/.test(product?.ID as string))
        setProduct(product)
        setError(null)

        if (!acceptedCategories.includes(product.itemID)) {
          setIsConflictAlert(false)
        }
      } catch (err) {
        console.error(err)
        setError(err)
      }
    }
    getProduct()
    //eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (product) {
      for (const obj of trimKitList) {
        if (obj.hasOwnProperty(product.ID) && trimKitSize) {
          setTrimKit(obj[product.ID][trimKitSize])
        }
      }
      for (const obj of refrigeratorIcemakerList) {
        if (obj.hasOwnProperty(product.ID)) {
          setIcemaker(obj[product.ID])
        }
      }
    }
    const accessoryId = trimKit !== '' ? trimKit : icemaker
    //Second api call to get the product's availability
    const getProductAvailability = async () => {
      try {
        const productAvailability = await api.getReplacementProduct(
          id,
          productId,
          addressId || '',
          partRequestId || '',
          dcov,
          trimKitSize
        )
        setProductAvailability(productAvailability)
        setError(null)
      } catch (err) {
        console.error(err)
        setError(err)
      }
    }
    //Third api call to get the accessory's availability
    const getAccessoryAvailability = async () => {
      // TODO: rename this method in api
      try {
        const accessory = await api.getReplacementProduct(
          id,
          accessoryId,
          addressId || '',
          partRequestId || '',
          dcov
        )

        setAccessory(accessory)
        setLoadingAccessory(false)
        setError(null)
      } catch (err) {
        console.error(err)
        setError(err)
      }
    }
    if (!isCustomer && (trimKit !== '' || icemaker !== '')) {
      setLoadingAccessory(true)
      getProductAvailability()
      getAccessoryAvailability()
    }
    //eslint-disable-next-line
  }, [product, trimKit, icemaker])

  const orderNow = async () => {
    setConfirmStatus('loading')
    try {
      const confirmation = await api.confirmReplacement(
        replacementId,
        productId
      )
      console.log('Successful confirmation', confirmation)
      history.push(`/${replacementId}/shop/${productId}/checkout`)
      setConfirmStatus('ok')
    } catch (err) {
      console.error(
        `There was an error confirming product '${productId}' for replacement '${replacementId}'`,
        err
      )
      setConfirmStatus('error')
    }
  }

  const attributes = useMemo<appliancepb.Product.IAttributeValuesPair[]>(() => {
    if (!product) return []
    return product.attributeGroups.reduce(
      (acc: appliancepb.Product.IAttributeValuesPair[], group) =>
        group.attributes ? [...acc, ...group.attributes] : acc,
      []
    )
  }, [product])

  return (
    <>
      <section className="-mt-8 lg:-mt-0">
        {/* back button */}
        <Text
          id="product-detail-page-back"
          className="font-bold flex items-center cursor-pointer"
          onClick={() => history.goBack()}
        >
          <IconNavArrowLeft size="20" color="interactive" />
          <span className="ml-1">
            {localizedText('PRODUCT_DETAILS_BACK_LABEL')}
          </span>
        </Text>
        {!isCustomer && isDesktop && (
          <div className="sticky h-0" style={{ top: '6rem' }}>
            <div
              className="mt-4 mr-0 flex justify-end translate-x-full"
              id="product-detail-page-dispatch-id-card"
            >
              <DispatchIdCard
                style={{ transform: 'translateX(100%)' }}
                wrapperClasses="pl-8"
              />
            </div>
          </div>
        )}
        {product ? (
          /* product info */
          <article className="mt-4 relative">
            <div className="mb-4 lg:mb-4">
              <Text
                id="product-detail-t-description"
                variant={`${isDesktop ? 'heading-02' : 'heading-04'}`}
              >
                {product.description}
              </Text>
              <Text id="product-detail-t-ID" className="helper-text">
                {product.ID}
              </Text>
            </div>
            {!isCustomer && !isDesktop && (
              <div
                className="mb-3 max-w-full overflow-x-scroll"
                id="product-detail-dispatch-id-card-2"
              >
                <DispatchIdCard
                  wrapperClasses="flex pb-3"
                  elemsClasses="pr-4 flex flex-col justify-between"
                />
              </div>
            )}
            <div className="lg:grid grid-cols-2 col-gap-24">
              {/* images */}
              <div
                className="lg:order-2 mb-4 lg:mb-0 flex w-full self-start"
                id="product-detail-page-carousel"
              >
                <SelectedSlideProvider>
                  <Carousel
                    product={product}
                    isGiftCard={memoGiftCard}
                    isClickable={true}
                  />
                </SelectedSlideProvider>
              </div>
              <div className="lg:order-1">
                {/* dimensions */}
                {!isGiftCard && (
                  <div className="mb-2">
                    <Text
                      id="product-detail-page-detail-sizes"
                      variant="heading-05"
                      textTemplateKey="PRODUCT_DETAILS_DIMENSIONS"
                      textTemplateData={{ unit: parsedUnit }}
                      color="primary"
                    />
                    <Text
                      variant="heading-05"
                      color="primary"
                    >{`${height}h x ${width}w x ${depth}d`}</Text>
                  </div>
                )}
                {/* price */}
                <div className="mb-2">
                  {!replacementId && product.IsCoupa && (
                    <Text
                      id="product-detail-product-coupa"
                      variant="caption"
                      className="font-bold mb-1 uppercase"
                      color="success"
                      textTemplateKey="PRODUCT_COUPA"
                    />
                  )}
                  <>
                    <div className="flex">
                      {!isCustomer && (
                        <Text
                          variant="heading-05"
                          className="mt-4 mr-1"
                          textTemplateKey="PRODUCT_DETAIL_PRODUCT_AVAILABILTY_LABEL"
                        />
                      )}
                      {!productAvailability
                        ? !product.IsAvailableForZip && (
                            <Text
                              id="product-detail-page-product-for-zip"
                              variant="heading-05"
                              className="mt-1 ml-2"
                              color="error"
                              textTemplateKey="PRODUCT_UNAVAILABLE"
                            />
                          )
                        : !productAvailability.IsAvailableForZip && (
                            <Text
                              id="product-detail-page-product-for-zip"
                              variant="heading-05"
                              className="mt-1 ml-2"
                              color="error"
                              textTemplateKey="PRODUCT_UNAVAILABLE"
                            />
                          )}
                      {!productAvailability ? (
                        <DeliveryDate product={product} isHeader={true} />
                      ) : (
                        <DeliveryDate
                          product={productAvailability}
                          isHeader={true}
                        />
                      )}
                    </div>
                    {!loadingAccessory ? (
                      accessory ? (
                        <div className="flex mt-1">
                          <Text
                            variant="heading-05"
                            className="mt-4 mr-1"
                            textTemplateKey="PRODUCT_DETAIL_ACCESSORY_AVAILABILTY_LABEL"
                          />
                          {!accessory.IsAvailableForZip && (
                            <Text
                              id="product-detail-page-accessory-for-zip"
                              variant="heading-05"
                              className="mt-1 ml-2"
                              color="error"
                              textTemplateKey="PRODUCT_UNAVAILABLE"
                            />
                          )}
                          <DeliveryDate product={accessory} isHeader={true} />
                        </div>
                      ) : (
                        <></>
                      )
                    ) : (
                      /* loading */
                      <div
                        id="product-detail-page-accessory-loading"
                        className="mt-1 flex justify-left"
                      >
                        <ProgressIndicator size="medium" color="interactive" />
                      </div>
                    )}
                  </>
                  <br></br>
                  {replacementId ? (
                    <>
                      <Text
                        id="product-detail-t-customer-price"
                        variant="heading-03"
                      >
                        {!isGiftCard
                          ? formatCentsCurrency(product.customerPrice)
                          : formatCentsCurrency(cashInLieu)}
                      </Text>
                    </>
                  ) : (
                    <div className="flex justify-left items-center">
                      <div className="grid grid-cols-2">
                        <Text
                          variant="heading-05"
                          className="font-normal text-right"
                        >
                          Base Price:
                        </Text>
                        <Text
                          id="product-detail-t-base-price"
                          variant="heading-05"
                          className="text-right"
                        >
                          {formatCentsCurrency(product.basePrice || 0)}
                        </Text>
                        <Text
                          variant="heading-05"
                          className="font-normal text-right"
                        >
                          CIL Price:
                        </Text>
                        <Text
                          id="product-detail-t-price-with-tax"
                          variant="heading-05"
                          className="text-right"
                        >
                          {formatCentsCurrency(
                            product.defaultPriceWithTax || 0
                          )}
                        </Text>
                        <Text
                          variant="heading-05"
                          className="font-normal text-right"
                        >
                          Upgrade Price:
                        </Text>
                        <Text
                          id="product-detail-t-upgrade-price"
                          variant="heading-05"
                          className="text-right"
                        >
                          {formatCentsCurrency(
                            product.upgradePriceWithTax || 0
                          )}
                        </Text>
                      </div>
                    </div>
                  )}
                </div>
                {/* proceed to checkout button */}
                <div className="mb-8 lg:mb-10">
                  {replacementId ? (
                    <div>
                      <Button
                        id="product-detail-order-now"
                        className="mb-2 mt-4"
                        size={`${isDesktop ? 'large' : 'medium'}`}
                        label={
                          isGiftCard
                            ? localizedText(
                                'GIFTCARD_DETAIL_PROCEED_TO_CHECKOUT_BTN'
                              )
                            : localizedText(
                                'PRODUCT_DETAIL_PROCEED_TO_CHECKOUT_BTN'
                              )
                        }
                        onClick={orderNow}
                        loading={confirmStatus === 'loading'}
                        disabled={!product.IsAvailableForZip}
                      />

                      {confirmStatus === 'error' && (
                        <Text
                          id="product-detail-t-order-error"
                          className="font-bold"
                          variant="helper-text"
                          color="error"
                          textTemplateKey="ERROR_CUSTOMER"
                        />
                      )}
                    </div>
                  ) : (
                    <>
                      <Link
                        id="product-detail-link-confirm"
                        to={{
                          pathname: `${location.pathname}/confirm`,
                          state: { confirmationId: id },
                        }}
                      >
                        <Button
                          id="product-detail-recomendation-button"
                          className="mt-4"
                          size={`${isDesktop ? 'large' : 'medium'}`}
                          label={localizedText(
                            'PRODUCT_DETAIL_SELECT_RECOMMENDATION_BTN'
                          )}
                          disabled={isConflictAlert}
                        />
                      </Link>
                      {isConflictAlert ? (
                        <Text
                          className="mt-2"
                          color="error"
                          textTemplateKey="CATEGORIES_ALERT_1"
                        />
                      ) : null}
                    </>
                  )}
                </div>
                {/* features */}
                {!isGiftCard ? (
                  <div>
                    <Text
                      variant="heading-05"
                      textTemplateKey="PRODUCT_DETAIL_FEATURES_TITLE"
                    />
                    <ProductFeatures features={product.bulletFeatures} />
                  </div>
                ) : (
                  <div>
                    <Text
                      variant="heading-05"
                      textTemplateKey="GIFTCARD_DETAIL_FEATURES_TITLE"
                      className="mb-2"
                    />
                    <Text
                      id="product-detail-cash-in-lieu"
                      variant="caption"
                      className="mb-2"
                      dangerouslySetInnerHTML={{
                        __html: localizedText(
                          'GIFTCARD_DETAIL_FEATURES_DESCRIPTON',
                          {
                            cil: formatCentsCurrency(cashInLieu),
                          }
                        ),
                      }}
                    ></Text>
                    <Text
                      variant="caption"
                      textTemplateKey="GIFTCARD_DETAIL_FEATURES_WARNING"
                    />
                  </div>
                )}
              </div>
            </div>
            {/* attributes */}
            <ProductAttributes
              id="product-detail-page"
              attributes={attributes}
            />
            {/* proceed to checkout button */}
            <div className="mt-6">
              {replacementId ? (
                <div>
                  <Button
                    id="product-detail-order-now"
                    className="mb-2"
                    size={`${isDesktop ? 'large' : 'medium'}`}
                    label={
                      isGiftCard
                        ? localizedText(
                            'GIFTCARD_DETAIL_PROCEED_TO_CHECKOUT_BTN'
                          )
                        : localizedText(
                            'PRODUCT_DETAIL_PROCEED_TO_CHECKOUT_BTN'
                          )
                    }
                    onClick={orderNow}
                    loading={confirmStatus === 'loading'}
                    disabled={!product.IsAvailableForZip}
                  />

                  {confirmStatus === 'error' && (
                    <Text
                      id="product-detail-t-customer-error"
                      className="font-bold"
                      variant="helper-text"
                      color="error"
                      textTemplateKey="ERROR_CUSTOMER"
                    />
                  )}
                </div>
              ) : (
                <>
                  <Link
                    id="product-detail-page-link-confirm"
                    to={{
                      pathname: `${location.pathname}/confirm`,
                      state: { confirmationId: id },
                    }}
                  >
                    <Button
                      id="product-detail-page-recomendation-button"
                      className="mt-4"
                      size={`${isDesktop ? 'large' : 'medium'}`}
                      label={
                        isGiftCard
                          ? localizedText(
                              'GIFTCARD_DETAIL_PROCEED_TO_CHECKOUT_BTN'
                            )
                          : localizedText(
                              'PRODUCT_DETAIL_PROCEED_TO_CHECKOUT_BTN'
                            )
                      }
                      disabled={isConflictAlert}
                    />
                  </Link>
                  {isConflictAlert ? (
                    <Text
                      className="mt-2"
                      color="error"
                      textTemplateKey="CATEGORIES_ALERT_1"
                      textTemplateData={{
                        category: searchCategories,
                      }}
                    />
                  ) : null}
                </>
              )}
            </div>
          </article>
        ) : error ? (
          <div className="mt-8 flex justify-center">
            <Text
              id="product-detail-page-agent-customer-error"
              variant="heading-06"
              textTemplateKey={replacementId ? 'ERROR_CUSTOMER' : 'ERROR_AGENT'}
            />
          </div>
        ) : (
          /* loading */
          <div
            className="mt-8 flex justify-center"
            id="product-detail-page-spinner-wrapper"
          >
            <ProgressIndicator size="medium" data-testid="spinner" />
          </div>
        )}
      </section>
    </>
  )
}
