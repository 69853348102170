import { IconStarFull, useAppContext } from '@ftdr/blueprint-components-react'
import {
  ButtonComponent as Button,
  TextComponent as Text,
} from 'src/components/custom-fdr-components'
import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { AppState } from 'src/utils/shared-types'
import { appliancepb } from '../../services/protobuf-models/appliance-ms-protobuf-models'
import DeliveryDate from 'src/components/delivery-date/delivery-date'
import { formatCentsCurrency } from '../../utils/internationalization-helper'
import AddToCompareButton from '../../components/add-to-compare-button/add-to-compare-button'
import { isHSA } from 'src/utils/tenant-helper'

interface ProductCardProps {
  product: appliancepb.IProduct
  isAgent: boolean
}

const ProductCard = ({ product, isAgent }: ProductCardProps) => {
  const {
    appSettings: { localizedText },
  } = useAppContext()

  const replacement = useSelector((state: AppState) => state.replacement.data)

  const isLikeForLike =
    replacement?.replacementDetails?.likeForLikeProductID === product.ID
  const cashInLieu = replacement?.replacementDetails?.cashInLieu
  const isGiftCard = /^BH-/.test(product.ID as string)
  const [image] = product.images || []

  const { unit, height, width, depth } = product?.dimensions || {}
  const parsedUnit = unit === 'in' ? 'inches' : unit

  return (
    <div
      className={`flex relative rounded-3 lg:flex-col lg:items-center px-4 py-7 w-full xl:max-w-xs ${
        isHSA() ? 'shadow-floating' : 'bg-white'
      }`}
    >
      {isLikeForLike && !isGiftCard && (
        <div
          id={`product-card-start-label-${product.ID}`}
          className="bg-black absolute top-0 left-0 py-1 pl-2 pr-4 flex items-center"
        >
          <IconStarFull color="white" size="1rem" />
          <Text
            className="ml-1 text-white"
            variant="caption"
            textTemplateKey="PRODUCT_LIKE_FOR_LIKE_LABEL"
          />
        </div>
      )}

      <figure className="h-20 w-20 lg:h-48 lg:w-48 flex-shrink-0 max-w-full mr-4 lg:mr-0 flex items-center">
        <img
          id={`product-card-image-${product.ID}`}
          src={image?.url || '/noimage.jpg'}
          alt="Product"
          className="object-contain max-h-full max-w-full m-auto"
        />
      </figure>
      <div className="lg:flex lg:flex-col lg:h-full lg:pt-6 lg:text-center max-w-full">
        <div className={`hidden lg:block mb-2${isGiftCard ? ' lg:mb-7' : ''}`}>
          <Text variant="heading-06">{product.description}</Text>
          <Text variant="helper-text">{product.ID}</Text>
        </div>
        <Link
          to={(location) => `${location.pathname}/${product.ID}`}
          className="lg:hidden mb-2"
        >
          <Text
            id={`product-card-description-${product.ID}`}
            variant="heading-06"
          >
            {product.description}
          </Text>
          <Text
            id={`product-card-id-${product.ID}`}
            variant="helper-text"
            className={isGiftCard ? 'mb-2' : ''}
          >
            {product.ID}
          </Text>
        </Link>
        {!isGiftCard && (
          <div className="mb-2 lg:mb-7">
            <Text
              id={`product-card-is-units-${product.ID}`}
              variant="caption"
              className="lg:text-2"
            >{`Product Dimensions (${parsedUnit})`}</Text>
            <Text
              id={`product-card-sizes-${product.ID}`}
              variant="caption"
              className="lg:text-2"
            >{`${height}h x ${width}w x ${depth}d`}</Text>
          </div>
        )}
        <div className="mb-2 lg:hidden">
          {isAgent && product.IsCoupa && (
            <Text
              variant="caption"
              className="font-bold uppercase"
              color="success"
              textTemplateKey="PRODUCT_COUPA"
            />
          )}
          {!product.IsAvailableForZip && (
            <Text
              id={`product-card-upgrade-price-1-${product.ID}`}
              variant="caption"
              className="font-bold"
              color="error"
              textTemplateKey="PRODUCT_UNAVAILABLE"
            />
          )}
          {isAgent ? (
            <div className="flex lg:justify-center items-center my-2">
              <div className="grid grid-cols-2">
                <Text
                  variant="heading-06"
                  className="font-normal lg:ml-3 text-left"
                >
                  Base Price:
                </Text>
                <Text
                  id={`product-card-base-price-1-${product.ID}`}
                  variant="heading-06"
                  className="mr-3 text-right"
                >
                  {formatCentsCurrency(product.basePrice || 0)}
                </Text>
                <Text
                  variant="heading-06"
                  className="font-normal lg:ml-3 text-left"
                >
                  CIL Price:
                </Text>
                <Text
                  id={`product-card-default-price-1-${product.ID}`}
                  variant="heading-06"
                  className="mr-3 text-right"
                >
                  {formatCentsCurrency(product.defaultPriceWithTax || 0)}
                </Text>
                <Text
                  variant="heading-06"
                  className="font-normal lg:ml-3 text-left"
                >
                  Upgrade Price:
                </Text>
                <Text
                  id={`product-card-upgrade-price-1-${product.ID}`}
                  variant="heading-06"
                  className="mr-3 text-right"
                >
                  {formatCentsCurrency(product.upgradePriceWithTax || 0)}
                </Text>
              </div>
            </div>
          ) : (
            <Text
              id={`product-card-customer-price-${product.ID}`}
              variant="heading-06"
              className="mb-2 inline"
            >
              {!isGiftCard
                ? formatCentsCurrency(product.customerPrice || 0)
                : formatCentsCurrency(+cashInLieu!)}
            </Text>
          )}
        </div>

        <Text
          id={`product-card-warranty-${product.ID}`}
          variant="caption"
          className="lg:hidden"
        >
          {product?.warrantyCoverageMessage}
        </Text>

        <div className="hidden lg:block mt-auto">
          {isAgent && product.IsCoupa && (
            <Text
              id={`product-card-is-coupa-${product.ID}`}
              variant="caption"
              className="font-bold mb-1 uppercase"
              color="success"
              textTemplateKey="PRODUCT_COUPA"
            />
          )}
          {!product.IsAvailableForZip && (
            <Text
              id={`product-card-is-av-${product.ID}`}
              variant="caption"
              className="font-bold"
              color="error"
              textTemplateKey="PRODUCT_UNAVAILABLE"
            />
          )}
          <DeliveryDate product={product} />
          {isAgent ? (
            <div className="flex justify-center items-center my-2">
              <div className="grid grid-cols-2">
                <Text
                  variant="heading-06"
                  className="font-normal ml-3 text-left"
                >
                  Base Price:
                </Text>
                <Text
                  id={`product-card-base-price-${product.ID}`}
                  variant="heading-06"
                  className="mr-3 text-right"
                >
                  {formatCentsCurrency(product.basePrice || 0)}
                </Text>
                <Text
                  variant="heading-06"
                  className="font-normal ml-3 text-left"
                >
                  CIL Price:
                </Text>
                <Text
                  id={`product-card-default-${product.ID}`}
                  variant="heading-06"
                  className="mr-3 text-right"
                >
                  {formatCentsCurrency(product.defaultPriceWithTax || 0)}
                </Text>
                <Text
                  variant="heading-06"
                  className="font-normal ml-3 text-left"
                >
                  Upgrade Price:
                </Text>
                <Text
                  id={`product-card-upgrade-price-${product.ID}`}
                  variant="heading-06"
                  className="mr-3 text-right"
                >
                  {formatCentsCurrency(product.upgradePriceWithTax || 0)}
                </Text>
              </div>
            </div>
          ) : (
            <Text
              id={`product-card-currency-${product.ID}`}
              variant="heading-04"
              className={`${isGiftCard ? 'block mb-4' : 'inline mb-2'}`}
            >
              {!isGiftCard
                ? formatCentsCurrency(product.customerPrice || 0)
                : formatCentsCurrency(+cashInLieu!)}
            </Text>
          )}

          <Text
            id={`product-card-is-gift-card${product.ID}`}
            variant="caption"
            className="mb-4 inline-flex"
          >
            {product?.warrantyCoverageMessage}
          </Text>
        </div>

        <Link to={(location) => `${location.pathname}/${product.ID}`}>
          <Button
            id={`product-card-button-${product.ID}`}
            size="medium"
            label={localizedText('PRODUCT_CARD_VIEW_DETAILS_BTN')}
            className="inline-flex mt-4"
          />
        </Link>

        <AddToCompareButton
          id={product.ID || ''}
          containerStyle={{ visibility: isGiftCard ? 'hidden' : '' }}
          product={product}
        />
      </div>
    </div>
  )
}
export default ProductCard
